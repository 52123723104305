import * as React from "react"
import { Link } from "gatsby"
import Container from "../components/container"

const Layout = ({ location, title, invert, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath

  return (
    <div className={`main ${invert ? "bg-invert" : ""}`}>
      <nav className="global-nav sticky top-0 z-10">
        <div className="global-header container flex flex-wrap justify-between items-center mx-auto">
          <Link className="header-link-home text-xl md:text-2xl" to="/">
            {title}
          </Link>

          <div id="menuToggle" className="block md:hidden">
            <input id="menuToggleControl" type="checkbox" />
            <label for="menuToggleControl" style="display: none;">
              Menu Toggle Control
            </label>

            <span></span>
            <span></span>
            <span></span>

            <ul
              id="menu"
              className="md:hidden flex flex-col md:text-sm md:font-medium mb-0 w-full"
            >
              <li>
                <Link
                  className="block py-2 pr-4 pl-3 text-white md:text-blue-700 md:p-0"
                  to="/about"
                >
                  About
                </Link>
              </li>
              <li className="relative dropdown-trigger flex flex-col">
                <Link
                  className="block py-2 pr-4 pl-3 text-white md:text-blue-700 md:p-0"
                  to="#"
                >
                  Reviews
                </Link>
                <input id="dropdownToggleControl" type="checkbox" />
                <label for="dropdownToggleControl" style="display: none;">
                  Dropdown Toggle Control
                </label>
                <ul className="hidden">
                  <li>
                    <Link
                      className="block py-2 pr-4 pl-3 text-white md:text-blue-700 md:p-0"
                      to="/psychic-source"
                    >
                      Psychic Source
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="block py-2 pr-4 pl-3 text-white md:text-blue-700 md:p-0"
                      to="/path-forward"
                    >
                      PathForward
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="block py-2 pr-4 pl-3 text-white md:text-blue-700 md:p-0"
                      to="/ask-now"
                    >
                      Ask Now
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link
                  className="block py-2 pr-4 pl-3 text-white md:text-blue-700 md:p-0"
                  to="/testimonials"
                >
                  Testimonials
                </Link>
              </li>
            </ul>
          </div>
          {/* <button
            data-collapse-toggle="mobile-menu"
            type="button"
            className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="mobile-menu"
            aria-expanded="false"
            onClick={() => setIsNavOpen(prev => !prev)}
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <svg
              className="hidden w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button> */}

          <div id="mobile-menu" className="hidden w-full md:block md:w-auto">
            <ul className="flex flex-col mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium mb-0 w-full">
              <li>
                <Link
                  className="block py-2 pr-4 pl-3 text-white md:text-blue-700 md:p-0"
                  to="/about"
                >
                  About
                </Link>
              </li>
              <li className="group flex">
                <Link
                  className="block py-2 pr-4 pl-3 text-white md:text-blue-700 md:p-0"
                  to="/"
                >
                  Reviews
                </Link>
                <ul className="dropdown relative md:absolute hidden ml-0 pt-6 group-hover:block">
                  <li>
                    <Link
                      className="block py-2 pr-4 pl-3 text-white md:text-blue-700 md:p-0"
                      to="/psychic-source"
                    >
                      Psychic Source
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="block py-2 pr-4 pl-3 text-white md:text-blue-700 md:p-0"
                      to="/path-forward"
                    >
                      PathForward
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="block py-2 pr-4 pl-3 text-white md:text-blue-700 md:p-0"
                      to="/ask-now"
                    >
                      Ask Now
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link
                  className="block py-2 pr-4 pl-3 text-white md:text-blue-700 md:p-0"
                  to="/testimonials"
                >
                  Testimonials
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <div data-is-root-path={isRootPath}>
        <main>{children}</main>
        <Container></Container>
      </div>
      <footer>
        <Container>
          <header className="text-2xl font-bold mb-8">
            Psychic Reading Services
          </header>
          <div className="grid grid-cols-1 md:grid-cols-2">
            <ul>
              <li className="menu-title">Reviews</li>
              <li className="menu-item">
                <a className="text-white" href="/psychic-source">
                  Psychic Source
                </a>
              </li>
              <li className="menu-item">
                <a className="text-white" href="/path-forward">
                  PathForward
                </a>
              </li>
              <li className="menu-item">
                <a className="text-white" href="/ask-now">
                  Ask Now
                </a>
              </li>
            </ul>
            <ul>
              <li className="menu-title">Company</li>
              <li className="menu-item">
                <a className="text-white" href="/about">
                  About
                </a>
              </li>
              <li className="menu-item">
                <a className="text-white" href="/privacy-policy">
                  Privacy Policy
                </a>
              </li>
              <li className="menu-item">
                <a className="text-white" href="/terms">
                  Terms
                </a>
              </li>
            </ul>
          </div>
          <p>© 2022 PsychicReadingServices.com. All Rights Reserved</p>
        </Container>
      </footer>
    </div>
  )
}

export default Layout
