import * as React from "react"

const Container = ({ location, title, children }) => {
  return (
    <div className="global-wrapper container  items-center mx-auto">
      {children}
    </div>
  )
}

export default Container
